import styled from "@emotion/styled";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import {
  Dispatch,
  forwardRef,
  MouseEvent,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { MenuRef } from "../../App";
import { ReactComponent as MenuClose } from "../icons/menuClose.svg";
import { ReactComponent as LogoIcon } from "../icons/logo.svg";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

gsap.registerPlugin(ScrollToPlugin);

const StyledMenuModal = styled.div`
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  position: fixed;
  z-index: 10;

  .top-section {
    height: 5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .menu-toggle-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 1rem;
    > span {
      margin-right: 0.5rem;
    }

    &:hover {
      color: #b2f6ff;
    }
  }

  .menu-section {
    position: absolute;
    width: 100%;
    bottom: 10%;
    padding-left: 10%;
    padding-right: 20%;
  }

  .logo-wrapper {
    cursor: pointer;
  }

  .menu-mid-section {
    display: flex;
    align-items: flex-end;
    margin-top: 2rem;
  }

  .menuitem {
    color: #fff;
    margin-right: 1rem;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      color: #b2f6ff;
    }
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.8rem;

    .top-section {
      height: 3rem;
    }

    .menu-mid-section {
      display: initial;
    }
  }
`;

const menuItems = [
  {
    name: "AI that works",
    href: "#aithatworks",
  },
  {
    name: "Our impact",
    href: "#ourimpact",
  },
  {
    name: "Our people",
    href: "#ourpeople",
  },
  {
    name: "Our solutions",
    href: "#oursolutions",
  },
  {
    name: "Your career",
    href: "#yourcareer",
  },
  {
    name: "Get in touch",
    href: "#getintouch",
  },
];

const Modal = forwardRef<MenuRef, ModalProps>(
  ({ setIsModalOpen }, ref) => {
    const menuRefs = (
      ref as MutableRefObject<MenuRef | null>
    ).current;

    const modalRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();
    const navigate = useNavigate();

    const removeAnimation = () => {
      gsap.to(modalRef.current, {
        y: -1000,
        opacity: 0,
        duration: 0.5,
        onComplete: () => setIsModalOpen(false),
      });
    };

    const handleModalClose = () => {
      removeAnimation();
      document.body.style.overflowY = "scroll";
    };

    const handleMenuClick = (
      event: MouseEvent,
      href: string,
    ) => {
      event.preventDefault();
      navigate('/');
      if (menuRefs) {
        if (width && width > 1100) {
          const linkItem = menuRefs.panels.current.filter(
            (item) => `#${item.id}` === href,
          )[0].offsetLeft;
          gsap.to("html", {
            scrollTo: linkItem,
            duration: 1.5,
          });
        } else {
          gsap.to(window, {
            scrollTo: href,
            duration: 1.5,
          });
        }
      }
      removeAnimation();
      document.body.style.overflowY = "scroll";
    };

    useEffect(() => {
      gsap.from(modalRef.current, {
        x: 0,
        y: -1000,
        opacity: 0,
        duration: 0.5,
      });
    }, []);

    return (
      <StyledMenuModal ref={modalRef}>
        <div className="top-section">
          <div
            className="menu-toggle-wrapper"
            onClick={handleModalClose}
          >
            <span>close</span>
            <MenuClose />
          </div>
        </div>
        <div className="menu-section">
          <div
            className="logo-wrapper"
            onClick={(event) =>
              handleMenuClick(event, "#home")
            }
          >
            <LogoIcon />
          </div>
          <div className="menu-mid-section">
            <div className="menu-wrapper">
              {menuItems.map(({ name, href }) => (
                <div
                  className="menuitem"
                  key={name}
                  onClick={(event) =>
                    handleMenuClick(event, href)
                  }
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </StyledMenuModal>
    );
  },
);

export default Modal;
