import { useEffect, useRef, forwardRef, MutableRefObject, } from 'react'
import { useLocation } from 'react-router-dom'
import gsap from "gsap";
import { MenuRef } from "../../App";
import styled from "@emotion/styled";
import { useWindowSize } from "../../hooks/useWindowSize";

const StyledWrapper = styled.div`
  position: relative;
`;

const ScrollToAnchor = forwardRef<MenuRef>(
    (props, ref) => {
        const menuRefs = (
            ref as MutableRefObject<MenuRef>
        ).current;

        const location = useLocation();
        const lastHash = useRef('');

        const { width } = useWindowSize();

        useEffect(() => {
            if (location.hash) {
              lastHash.current = location.hash;
              if (width && width > 1100) {
                if (menuRefs) {
                    const linkItem = menuRefs.panels.current.filter(
                      (item) => `#${item.id}` === lastHash.current,
                    )[0].offsetLeft;
                    gsap.to("html", {
                      scrollTo: linkItem,
                      duration: 1.5,
                    });
                    lastHash.current = '';
                  }
              } else {
                gsap.to(window, {
                    scrollTo: lastHash.current,
                    duration: 1.5,
                  });
              }
              lastHash.current = '';
            }
          }, [location, menuRefs, width]);

        return <StyledWrapper />;
    }
);

export default ScrollToAnchor;
