import {
  MutableRefObject,
  RefObject,
  useRef,
  useState,
} from "react";
import "./App.css";
import styled from "@emotion/styled";
import Menu from "./components/Menu/Menu";
import { useWindowSize } from "./hooks/useWindowSize";
import Desktop from "./appwrappers/Desktop";
import Modal from "./components/Modal/Modal";
import Mobile from "./appwrappers/Mobile";
import ReactGA from "react-ga4";
import ScrollToAnchor from "./components/ScrollToAnchor/ScrollToAnchor";
import { BrowserRouter } from 'react-router-dom';

export interface MenuRef {
  panels: MutableRefObject<HTMLElement[]>;
  container: RefObject<HTMLDivElement>;
}

const StyledWrapper = styled.div`
  position: relative;
`;

ReactGA.initialize("G-61R8F8BGRP");

export default function App() {
  const panels = useRef<HTMLElement[]>([]);
  const panelsContainer = useRef<HTMLDivElement>(null);
  const menuRef = useRef<MenuRef | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { width } = useWindowSize();

  menuRef.current = {
    panels,
    container: panelsContainer,
  };

  return (
    <BrowserRouter>
    <StyledWrapper>
    {width && width > 1100 ? (
        <Desktop ref={menuRef} />
      ) : (
        <Mobile ref={menuRef} />
      )}
      <Menu setIsModalOpen={setIsModalOpen} ref={menuRef} />
      {isModalOpen && (
        <Modal
          ref={menuRef}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    <ScrollToAnchor ref={menuRef} />  
    </StyledWrapper>
    </BrowserRouter>
  );
}
