import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Cube from "../components/svg/cube/Cube";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      svg {
        height: 15rem;
      }
    }
  }
`;

const FourthScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <Cube />
      </div>
      <PageText
        //upper="building never-seen-before solutions"
        lower="Impact for your business"
        multi={
          <>
            <div>
              Our solutions contribute at the core of our partners’ businesses by quoting millions of price points, forecasting millions of movements, evaluating hundreds of thousands of options, and triggering thousands of decisions and actions daily.
            </div>
            <div>
            We partner with FTSE 100, Fortune 500 companies, and industry disruptors across multiple continents, driving millions in bottom line impact that is fast, scalable, and lasting.
            </div>
          </>
        }
        ref={ref}
      />
      <SectionText text="Our impact" />
    </ScreenWrapper>
  );
});

export default FourthScreen;
