import styled from "@emotion/styled";
import gsap from "gsap";
import {
  Dispatch,
  forwardRef,
  MouseEvent,
  MutableRefObject,
  SetStateAction,
} from "react";
import { ReactComponent as MenuIcon } from "../icons/menuIcon.svg";
import { ReactComponent as LogoIcon } from "../icons/logo.svg";
import { MenuRef } from "../../App";
import { useWindowSize } from "../../hooks/useWindowSize";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useNavigate } from "react-router-dom";

interface MenuProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

gsap.registerPlugin(ScrollToPlugin);

const StyledMenuWrapper = styled.nav`
  height: 5rem;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #000;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  .logo-wrapper {
    padding: 0 1rem;
  }
  .menu-wrapper {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    > span {
      margin-right: 0.5rem;
    }

    &:hover {
      color: #b2f6ff;
      stroke: #b2f6ff;
    }
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.8rem;
    height: 3rem;
  }
`;

const Menu = forwardRef<MenuRef, MenuProps>(
  ({ setIsModalOpen }, ref) => {
    const menuRefs = (
      ref as MutableRefObject<MenuRef | null>
    ).current;

    const { width } = useWindowSize();

    const navigate = useNavigate();

    const handleMenuClick = (
      event: MouseEvent,
      href: string,
    ) => {
      event.preventDefault();
      navigate('/');
      if (menuRefs) {
        if (width && width > 1100) {
          const linkItem = menuRefs.panels.current.filter(
            (item) => `#${item.id}` === href,
          )[0].offsetLeft;
          gsap.to("html", {
            scrollTo: linkItem,
            duration: 1.5,
          });
        } else {
          gsap.to(window, {
            scrollTo: href,
            duration: 1.5,
          });
        }
      }
    };

    const handleModaOpen = () => {
      setIsModalOpen(true);
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    };

    return (
      <StyledMenuWrapper>
        <div
          className="logo-wrapper"
          onClick={(event) =>
            handleMenuClick(event, "#home")
          }
        >
          <LogoIcon />
        </div>
        <div
          className="menu-wrapper"
          onClick={handleModaOpen}
        >
          <span>menu</span>
          <MenuIcon />
        </div>
      </StyledMenuWrapper>
    );
  },
);

export default Menu;
