import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Head from "../components/svg/head/Head";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      padding-left: 4rem;
      height: 15rem;
    }
  }
`;

const FifthScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <Head />
      </div>
      <PageText
        //upper={"balance of ai & hi - "}
        lower="Our people"
        multi={
          <>
            <div>
              Our 30+ high talent-density team apprenticed at prestigious investment banks, analytics powerhouses, leading management consultancies, and tech distruptors.
            </div>
            <div>
              Not only do we build AI, but we also advocate, debate, and educate on it at board meetings, keynotes, and in academia.
            </div>
            <div>
              We contribute to science by developing assets and methods that fuse AI with Mathematics to solve problems more robustly and effectively.
            </div>
            <div>
              Working for industry-shaping clients on their most relevant and challenging problems provides exceptional learning opportunities that fosters individual and team development. 
            </div>
          </>
        }
        ref={ref}
      />
      <SectionText text="Our people" />
    </ScreenWrapper>
  );
});

export default FifthScreen;
