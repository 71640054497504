import styled from "@emotion/styled";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import {
  forwardRef,
  MutableRefObject,
  RefObject,
  useLayoutEffect,
  useRef,
} from "react";
import { MenuRef } from "../App";
import Amorph from "../components/svg/amorph/Amorph";
import AmorphOriginal from "../components/svg/amorph/AmorphOriginal";
import Cube from "../components/svg/cube/Cube";
import Head from "../components/svg/head/Head";
import OpenLines from "../components/svg/open/OpenLines";
import Spiral from "../components/svg/spiral/Spiral";
import SpiralOriginal from "../components/svg/spiral/SpiralOriginal";
import EighthScreen from "../screens/EighthScreen";
import FifthScreen from "../screens/FifthScreen";
import FirstScreen from "../screens/FirstScreen";
import FourthScreen from "../screens/FourthScreen";
import NinethScreen from "../screens/NinethScreen";
import SecondScreen from "../screens/SecondScreen";
import SixthScreen from "../screens/SixthScreen";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(SplitText);

export interface TextRef {
  textwrapperRef: RefObject<HTMLDivElement>;
  textpRef: RefObject<HTMLParagraphElement>;
  textmultiRef?: MutableRefObject<HTMLDivElement[] | null>;
}

const StyledAppWrapper = styled.div`
  position: relative;
  width: 700vw;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;

  > .panel {
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    z-index: 0;
    > .svg-wrapper {
      height: 80%;
      width: 100%;
    }
  }
`;

const Desktop = forwardRef<MenuRef>((props, ref) => {
  const menuRefs = (ref as MutableRefObject<MenuRef>)
    .current;

  const panels = menuRefs?.panels;
  const panelsContainer = menuRefs?.container;

  const amorphRef = useRef<SVGPathElement>(null);
  const amorphOriginalRef = useRef<SVGPathElement>(null);
  const spiralOriginalRef = useRef<SVGPathElement>(null);
  const spiralRef = useRef<SVGPathElement>(null);

  const text2wrapperRef = useRef<HTMLDivElement>(null);
  const text2pRef = useRef<HTMLParagraphElement>(null);

  const text3wrapperRef = useRef<HTMLDivElement>(null);
  const text3pRef = useRef<HTMLDivElement>(null);
  const text4wrapperRef = useRef<HTMLDivElement>(null);
  const text4pRef = useRef<HTMLDivElement>(null);
  const text5wrapperRef = useRef<HTMLDivElement>(null);
  const text5pRef = useRef<HTMLDivElement>(null);
  const text6wrapperRef = useRef<HTMLDivElement>(null);
  const text6pRef = useRef<HTMLDivElement>(null);
  const text7wrapperRef = useRef<HTMLDivElement>(null);
  const text7pRef = useRef<HTMLParagraphElement>(null);
  const text7multiRef = useRef<HTMLDivElement[]>([]);
  const text8wrapperRef = useRef<HTMLDivElement>(null);
  const text8pRef = useRef<HTMLDivElement>(null);
  const text8multiRef = useRef<HTMLDivElement[]>([]);

  const text2ref = useRef<TextRef | null>(null);
  const text3ref = useRef<TextRef | null>(null);
  const text4ref = useRef<TextRef | null>(null);
  const text5ref = useRef<TextRef | null>(null);
  const text6ref = useRef<TextRef | null>(null);
  const text7ref = useRef<TextRef | null>(null);
  const text8ref = useRef<TextRef | null>(null);

  text2ref.current = {
    textwrapperRef: text2wrapperRef,
    textpRef: text2pRef,
  };
  text3ref.current = {
    textwrapperRef: text3wrapperRef,
    textpRef: text3pRef,
  };
  text4ref.current = {
    textwrapperRef: text4wrapperRef,
    textpRef: text4pRef,
  };
  text5ref.current = {
    textwrapperRef: text5wrapperRef,
    textpRef: text5pRef,
  };
  text6ref.current = {
    textwrapperRef: text6wrapperRef,
    textpRef: text6pRef,
  };
  text7ref.current = {
    textwrapperRef: text7wrapperRef,
    textmultiRef: text7multiRef,
    textpRef: text7pRef,
  };
  text8ref.current = {
    textwrapperRef: text8wrapperRef,
    textmultiRef: text8multiRef,
    textpRef: text8pRef,
  };

  const createPanelsRefs = (
    panel: HTMLElement,
    index: number,
  ) => {
    panels.current[index] = panel;
  };

  useLayoutEffect(() => {
    const timeline = gsap.timeline();
    const totalPanels = panels.current.length;

    const scrollTween = gsap.to(panels.current, {
      x: () =>
        -(
          panelsContainer.current!.offsetWidth -
          window.innerWidth
        ) + "px",
      ease: "none",
      scrollTrigger: {
        id: "side-scroller",
        trigger: panelsContainer.current,
        pin: true,
        scrub: 1.6,
        snap: 1 / (totalPanels - 1),
        end: () =>
          "+=" +
          (panelsContainer.current!.offsetWidth -
            window.innerWidth),
      },
    });

    document.fonts.ready.then(() => {
      if (text2ref.current) {
        const text2wrapper =
          text2ref.current?.textwrapperRef;
        const text2P = text2ref.current?.textpRef;
        const childSplit = new SplitText(text2P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text2P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text2wrapper.current, {
          duration: 1,
          x: -200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[1],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[1],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });
      }

      if (text4ref.current) {
        const text4wrapper =
          text4ref.current?.textwrapperRef;
        const text4P = text4ref.current?.textpRef;
        const childSplit = new SplitText(text4P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text4P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text4wrapper.current, {
          duration: 1,
          x: 200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[2],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[2],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });
      }

      if (text5ref.current) {
        const text5wrapper =
          text5ref.current?.textwrapperRef;
        const text5P = text5ref.current?.textpRef;
        const childSplit = new SplitText(text5P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text5P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text5wrapper.current, {
          duration: 1,
          y: 200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[3],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[3],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });
      }

      if (text6ref.current) {
        const text6wrapper =
          text6ref.current?.textwrapperRef;
        const text6P = text6ref.current?.textpRef;
        const childSplit = new SplitText(text6P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text6P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text6wrapper.current, {
          duration: 1,
          x: -200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[4],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[4],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });
      }

      if (
        text8ref.current &&
        text8ref.current?.textmultiRef
      ) {
        const text8wrapper =
          text8ref.current?.textwrapperRef;
        const text8multi = text8ref.current?.textmultiRef;

        gsap.from(text8wrapper.current, {
          duration: 1,
          x: 200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[5],
            containerAnimation: scrollTween,
            horizontal: true,
            start: "left 20%",
            end: "right center",
            toggleActions: "play none none reset",
          },
        });

        if (text8multi.current) {
          text8multi.current.map((child, index) =>
            gsap.from(child, {
              duration: 0.5,
              delay: index * 0.5 + 1,
              y: 200,
              autoAlpha: 0,
              ease: "power4",
              scrollTrigger: {
                trigger: panels.current[5],
                containerAnimation: scrollTween,
                horizontal: true,
                start: "left 20%",
                end: "right center",
                toggleActions: "play none none reset",
              },
            }),
          );
        }
      }
    });

    if (amorphRef.current) {
      gsap.to(amorphOriginalRef.current, {
        morphSVG: {
          shape: amorphRef.current,
          shapeIndex: 566,
        },
        duration: 4,
        ease: "back.in(1.4)",
        repeat: -1,
        yoyo: true,
        yoyoEase: true,
        scrollTrigger: {
          trigger: panels.current[1],
          containerAnimation: scrollTween,
          start: "left center",
          end: "right left",
          horizontal: true,
          toggleActions: "play none none reset",
          id: "1",
        },
      });
    }

    if (spiralRef.current) {
      timeline.to(
        spiralOriginalRef.current,
        {
          morphSVG: {
            shape: spiralRef.current,
            shapeIndex: 10,
          },
          rotate: "90deg",
          duration: 1,
          scrollTrigger: {
            trigger: panels.current[4],
            containerAnimation: scrollTween,
            start: "left right",
            end: "right center",
            toggleActions: "play none none reset",
            id: "3",
            scrub: true,
          },
        },
        "+=1",
      );
    }

    return () => {
      const sc = ScrollTrigger.getAll();
      sc.map((trigger) => trigger.kill());
      panels.current = [];
    };
  }, [panelsContainer, panels, window.innerWidth]);
  return (
    <StyledAppWrapper ref={panelsContainer}>
      <section
        className="panel"
        id="home"
        ref={(e) => e && createPanelsRefs(e, 0)}
      >
        <FirstScreen />
        <OpenLines />
      </section>
      <section
        className="panel"
        id="aithatworks"
        ref={(e) => e && createPanelsRefs(e, 1)}
      >
        <SecondScreen ref={text2ref} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 511 491"
          style={{
            position: "absolute",
            right: "10%",
            zIndex: 0,
            stroke: "#b2f6ff",
            strokeWidth: "0.5px",
            fill: "none",
            height: "50%",
            overflow: "visible",
            willChange: "contents",
          }}
        >
          <Amorph ref={amorphRef} />
          <AmorphOriginal ref={amorphOriginalRef} />
        </svg>
      </section>
      <section
        className="panel"
        id="ourimpact"
        ref={(e) => e && createPanelsRefs(e, 2)}
      >
        <FourthScreen ref={text4ref} />
        <Cube />
      </section>
      <section
        className="panel"
        id="ourpeople"
        ref={(e) => e && createPanelsRefs(e, 3)}
      >
        <FifthScreen ref={text5ref} />
        <Head />
      </section>
      <section
        className="panel"
        id="oursolutions"
        ref={(e) => e && createPanelsRefs(e, 4)}
      >
        <SixthScreen ref={text6ref} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2139.21 1853.33"
          style={{
            position: "absolute",
            top: "-35%",
            right: "-10%",
            zIndex: 0,
            stroke: "#b2f6ff",
            strokeWidth: "0.5px",
            height: "150%",
            fill: "none",
          }}
        >
          <Spiral ref={spiralRef} />
          <SpiralOriginal ref={spiralOriginalRef} />
        </svg>
      </section>
      <section
        className="panel"
        id="yourcareer"
        ref={(e) => e && createPanelsRefs(e, 5)}
      >
        <EighthScreen ref={text8ref} />
      </section>
      <section
        className="panel"
        id="getintouch"
        ref={(e) => e && createPanelsRefs(e, 6)}
      >
        <NinethScreen />
      </section>
    </StyledAppWrapper>
  );
});

export default Desktop;
