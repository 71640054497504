import styled from "@emotion/styled";
import { forwardRef, useState } from "react";
import PageText from "../components/text/PageText";
import { ReactComponent as MenuClose } from "../components/icons/menuClose.svg";
import Spiral from "../components/svg/spiral/Spiral";
import { useWindowSize } from "../hooks/useWindowSize";
import SectionText from "../components/text/SectionText";
import { Modal } from "@mantine/core";
import { TextRef } from "../appwrappers/Desktop";

const StyledHoverWrapper = styled.div`
  display: flex;
  cursor: pointer;
  .hover-text-item {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    > span {
      margin-right: 0.5rem;
    }
  }

  .hover-data-wrapper {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    max-width: 60rem;
  }

  .hover-active {
    color: #b2f6ff;
  }

  @media screen and (max-width: 1300px) {
    .hover-text-item {
      font-size: 0.9rem;
    }

    .hover-data-wrapper {
      display: none;
    }
  }

  @media screen and (max-height: 900px) and (max-width: 1700px) {
    .hover-text-item {
      font-size: 0.9rem;
    }

    .hover-data-wrapper {
      font-size: 0.9rem;
      max-width: 45rem;
    }
  }
`;

const StyledWrapper = styled.div`
  z-index: 1;
  transition: all 200ms ease-in;

  .gradient {
    position: absolute;
    top: 0;
    z-index: 5;
    right: 40%;
    width: 60%;
    height: 100%;
    background: linear-gradient(
      90.76deg,
      rgba(0, 0, 0, 0.71) 63.65%,
      rgba(0, 0, 0, 0) 98.1%
    );
  }

  .text {
    position: absolute;
    top: 50%;
    left: 35%;
    z-index: 2;
  }

  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .gradient {
      display: none;
    }
    .svg-wrapper {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      svg {
        position: initial;

        stroke: #b2f6ff;
        stroke-width: 1.5px;
        height: 15rem;
        fill: none;
        > path {
          visibility: initial;
        }
      }
    }
  }
`;

const hoverItems = [
  {
    text: "AI-enhanced dynamic pricing",
    hoverText:
      "Our Pricing and Revenue Management/Yield Management solutions leverage AI to provide a more comprehensive and better forecast of the behavior of demand by advanced modeling of channel-specific customer behavior based on real-time insights from multiple data sources. Pricing decisions are optimized using micro-segmented conversion rates factoring in volume vs. revenue trade-off, sales strategy and flight-specific context. We deliver precise, risk-aware pricing that adapts to the dynamics of any market and actual business objectives.",
  },
  {
    text: "Ancillary pricing",
    hoverText:
      "Our AI-powered ancillary pricing solution learns customer behavior continuously from the full sales pipeline, and leverages data on ticket revenue, competition, and product portfolio to adapt prices. Our AI model has been in operation for years, delivering outstanding ancillary revenues at industry leaders across multiple continents... while responding to market shifts.",
  },
  {
    text: "AI-assisted operations",
    hoverText: "Our AI-assisted decision support tool (digital twin) empowers operators to monitor and optimize operations over the last x-days. The solution works seamlessly with any existing ops management system and combines AI, Mathematics, and disruptive tech to offer 360° data view (internal/external), a continuously updated AI-driven operational forecast, alerts, Value@Risk prioritization and an AI powered recommendation engine... searching continuously for and finding the best solutions under any conditions of both regular and irregular operations.",
  },
  {
    text: "Fleet pricing and location optimization",
    hoverText: "Our model helps fleet operators jointly optimize pricing and fleet location management to improve profit margins. The solution applies advanced data analysis to build a statistical model of customer behavior and a digital twin to evaluate a large number of possible scenarios. Profitability driven pricing decisions enable a more effective response to market dynamics than any former approach... offering greater growth opportunities while improving customer satisfaction.",
  },
  {
    text: "Contextual decision support",
    hoverText: "Our AI-powered recommendation engine optimizes operational processes. The solution empowers decision makers by integrating multiple data sources, evaluating relevant information, and determining optimal actions both proactively and reactively. Our model is capable of considering various scenarios, potential disruptions, constraints and cost functions... to recommend 'best solutions' from countless possibilities in any arbitrary context, exceeding human comprehension.",
  },
];

const HoverText = () => {
  const [hoverData, setHoverData] = useState<string>();
  const { width } = useWindowSize();
  return (
    <StyledHoverWrapper>
      <div className="hover-text-item-container">
        {hoverItems.map(({ text, hoverText }) => (
          <div
            className={`hover-text-item ${
              hoverData === hoverText && "hover-active"
            }`}
            key={text}
            onClick={() => setHoverData(hoverText)}
            {...(width && width > 1300
              ? {
                  onMouseEnter: () =>
                    setHoverData(hoverText),
                }
              : {})}
            {...(width && width > 1300
              ? {
                  onMouseLeave: () =>
                    setHoverData(undefined),
                }
              : {})}
          >
            <span>{text}</span>
            <MenuClose />
          </div>
        ))}
      </div>
      {hoverData && (
        <div className="hover-data-wrapper">
          {hoverData}
        </div>
      )}
      {width && width < 1300 && (
        <Modal
          withCloseButton={false}
          centered
          opened={!!hoverData}
          onClose={() => setHoverData(undefined)}
          styles={{
            modal: {
              backgroundColor: "rgba(255,255,255,1)",
              fontSize: "0.9rem",
            },
          }}
        >
          <div>{hoverData}</div>
        </Modal>
      )}
    </StyledHoverWrapper>
  );
};

const SixthScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <StyledWrapper>
      <div className="gradient"></div>
      <div className="svg-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2139.21 1853.33"
        >
          <Spiral />
        </svg>
      </div>
      <PageText
        //upper="we are at home in"
        lower="Select examples of our production-level solutions"
        //paragraph="our founders and people led businesses and led the digital and analytics foundations of world-renowned business disruptions. We are impactful in"
        other={<HoverText />}
        ref={ref}
      />
      <SectionText text="Our solutions" />
    </StyledWrapper>
  );
});

export default SixthScreen;
