import styled from "@emotion/styled";
import { forwardRef, RefObject } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Earth from "../components/svg/earth/Earth";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";
import { email } from "../staticText/staticText";

const ScreenWrapper = styled.div`
  height: 100%;
  .svg-wrapper {
    position: absolute;
    top: 20%;
    right: 5%;
    height: 60%;
  }

  p,
  .multi-wrapper {
    white-space: pre-line;
    margin-top: 5rem;
    max-width: 56rem;
  }

  a:hover {
    color: #b2f6ff;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      position: initial;
      display: flex;
      justify-content: center;
      margin: 1rem 0 2rem 0;
      height: 15rem;

      svg {
        height: 100%;
      }
    }
  }
`;

const EighthScreen = forwardRef<TextRef>((props, ref) => {
  const multiRef = (ref as RefObject<TextRef>)?.current
    ?.textmultiRef;
  const createMultiRefs = (
    multi: HTMLDivElement,
    index: number,
  ) => {
    if (multiRef?.current) {
      multiRef.current[index] = multi;
    }
  };
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <Earth />
      </div>
      <PageText
        lower="Your career"
        multi={
          <>
            <div ref={(e) => e && createMultiRefs(e, 0)}>
              Our organizational culture is characterized by flexibility, dedication, collaboration and fostering an environment where our teams thrive. This is  the right place for you if you are prepared to navigate dynamic changes, explore inventive solutions, and contribute to substantive problem-solving initiatives. Join us if you want to be in the forefront of analytics and agentive AI.
            </div>
            <div ref={(e) => e && createMultiRefs(e, 1)}>
              We are currently seeking candidates for the following open roles:
            </div>
            <div ref={(e) => e && createMultiRefs(e, 2)}>
              <ul>
                <li>Senior Software Engineer [<a href={`https://daalab.jobs.personio.com/job/1758875?display=en`} rel="noopener noreferrer" target = "_blank">apply here</a>]</li>
                <li>Software Test Engineer [<a href={`https://daalab.jobs.personio.com/job/1795244?display=en`} rel="noopener noreferrer" target = "_blank">apply here</a>]</li>
                <li>Data Scientist [<a href={`https://daalab.jobs.personio.com/job/1583550?display=en`} rel="noopener noreferrer" target = "_blank">apply here</a>]</li>
              </ul>
            </div>
          </>
        }
        ref={ref}
      />
      <SectionText text="Your career" />
    </ScreenWrapper>
  );
});

export default EighthScreen;
