import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Amorph from "../components/svg/amorph/Amorph";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      svg {
        margin: 2rem 0;
        stroke: #b2f6ff;
        stroke-width: 0.5px;
        fill: none;
        height: 15rem;
        > path {
          visibility: initial;
        }
      }
    }
  }
`;

const SecondScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 511 491"
        >
          <Amorph />
        </svg>
      </div>
      <PageText
        //upper="we do what we know best"
        lower="AI that works"
        multi={
          <>
            <div>
              We deliver production-level AI solutions, running at scale for years at industry leaders, solving relevant problems no one solved before.
            </div>
            <div>
              We dare to put human intelligence of users, experts and common sense to the center of our solutions to get the right balance with AI.
            </div>
            <div>
              We achieve success by combining AI, Mathematical Modeling, Disruptive Tech and Business Transformation at highest level, driven by our high talent-density team.
            </div>
          </>
        }
        ref={ref}
      />
      <SectionText text="Our AI" />
    </ScreenWrapper>
  );
});

export default SecondScreen;
